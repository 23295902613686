import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import twitter from "../../images/react-icons/twitter-blue-logo.svg"
import linkedin from "../../images/svg/linkedin.png"
import * as styles from "./authorDetailSection.module.scss"

const ProfileDetail = ({ data }) => {
  return (
    <section>
      <Container>
        <Row>
          <Col
            md={6}
            className="d-md-flex justify-content-center align-items-center"
          >
            <GatsbyImage
              image={getImage(data?.image?.localFile)}
              className="rounded-circle"
              placeholder="blurred"
              alt={"author.node.name"}
              style={{ height: "164px", width: "164px" }}
            />
            <div className="px-md-3">
              <h1 className="main-heading-36 main-author-name mb-0">
                {data?.name}
              </h1>
              <p className={styles.title}>{data?.status}</p>
              {data?.socialMedia &&
                data?.socialMedia?.map(({ url, title }, i) =>
                  title.toLowerCase() === "linkedin" ? (
                    <a href={url} target="_blank" key={i} rel="noreferrer">
                      <img
                        src={linkedin}
                        className={styles.authorSocial}
                        alt="icon"
                      />
                    </a>
                  ) : title.toLowerCase() === "twitter" ? (
                    <a href={url} target="_blank" key={i} rel="noreferrer">
                      <img
                        src={twitter}
                        alt="twitter"
                        className="ml-1 mr-1"
                        style={{ height: "16px", width: "16px" }}
                      />
                    </a>
                  ) : (
                    ""
                  )
                )}
            </div>
          </Col>
          <Col
            md={6}
            className={`${styles.description} mt-md-5 mt-md-0`}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></Col>
        </Row>
      </Container>
    </section>
  )
}

export default ProfileDetail
