import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import rightArr from "../../images/svg/rightArr.svg"
import AuthurLatestPost from "../common/AuthurLatestPosts"
import * as styles from "./latestBlogs.module.scss"

const latestBlogs = ({ author, posts }) => {
  return (
    <section className={styles.section}>
      <div className="text-center mb-3 px-3">
        <h2 className={styles.title}>Latest Blogs</h2>
      </div>
      <Container>
        <Row>
          {posts?.slice(0, 4)?.map((post, i) => (
            <Col xs={12} md={6} lg={3} key={i}>
              <div className="px-1 pb-5">
                <AuthurLatestPost post={post} insights={true} author={author} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="text-center mt-2">
        <Link to={`/blog/author/${author?.slug}/`}>
          <Button className={styles.btn}>
            <p className={styles.view}>View All </p>{" "}
            <img src={rightArr} decoding="async" loading="lazy" alt="icon" />
          </Button>
        </Link>
      </div>
    </section>
  )
}

export default latestBlogs
