import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { stringTruncate } from "../../utils"
import * as styles from "./AuthurLatestPost.module.scss"

const AuthurLatestPost = ({ post, author }) => {
  const { title, slug, featuredImage, readingTime, isWP, publishedOn } = post // prettier-ignore

  return (
    <Card className={styles.card}>
      <Link to={`/blog/${slug}/`}>
        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={getImage(featuredImage?.localFile)}
          alt={title}
          style={{ height: 187 }}
        />
      </Link>
      <Card.Body className={styles.containerA}>
        <Link to={`/blog/${slug}/`}>
          <h3>{title}</h3>
          <p className={styles.text}>
            {stringTruncate(post?.seo?.metaDesc, 50)}
          </p>
        </Link>
        <div>
          <Link to={`/author/${author?.slug}/`}>
            <Row className={styles.avatar}>
              <Col xs={2}>
                <GatsbyImage
                  image={getImage(author?.image?.localFile)}
                  className="rounded-circle"
                  decoding="async"
                  loading="lazy"
                  placeholder="blurred"
                  alt={author?.name}
                  style={{ height: "40px", width: "40px" }}
                />
              </Col>
              <Col xs={10}>
                <small>{author?.name} </small>
                <div className={styles.date}>
                  <small>
                    {publishedOn} |{" "}
                    {isWP ? `${readingTime || 0} min read` : readingTime}
                  </small>
                </div>
              </Col>
            </Row>
          </Link>
        </div>
      </Card.Body>
    </Card>
  )
}

export default AuthurLatestPost
