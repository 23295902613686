import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Spinner from "react-bootstrap/Spinner"
import { encodeToFormData, isEmail } from "../../utils"
import * as styles from "./Newsletter.module.scss"

const Newsletter = () => {
  const [status, setStatus] = useState("init")
  const initValues = { email: "" }
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "email":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setStatus("submitting")
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9009`
    const formData = encodeToFormData(values)
    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        setValues(initValues)
        setStatus("success")
      } else {
        // console.log(result)s
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <Form name="Newsletter Form" method="POST" onSubmit={handleSubmit}>
      <InputGroup>
        <FormControl
          type="email"
          class="font-16"
          aria-label="Email"
          placeholder="Type your email here"
          aria-describedby="Newsletter"
          className={styles.field}
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={errors.email}
          style={{ width: "100%" }}
        />
        <InputGroup.Append>
          <Button
            type="submit"
            class="font-16"
            className={styles.btn}
            aria-label="Send"
            disabled={status === "submitting"}
            id="author_profile_newsletter_btn_ga"
          >
            {status === "submitting" ? (
              <React.Fragment>
                <Spinner
                  variant="primary"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Sending</span>
              </React.Fragment>
            ) : (
              "Subscribe"
            )}
          </Button>
        </InputGroup.Append>
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </InputGroup>
      {status === "success" && (
        <div className="mt-1 small" style={{ color: "#00ff7f" }}>
          You have successfully subscribed our Newsletter.
        </div>
      )}
      {status === "failed" && (
        <div className="mt-1 small" style={{ color: "#ff3800" }}>
          Oops! Something went wrong. Please try again.
        </div>
      )}
    </Form>
  )
}

export default Newsletter
