import React from "react"
import Container from "react-bootstrap/Container"
import leftArr from "../../images/svg/leftArr.svg"
import rightArr from "../../images/svg/rightArr.svg"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./authors.module.scss"
import AvatarCard from "./avatarCard"
import "./sliderDotClass.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          src={rightArr}
          decoding="async"
          loading="lazy"
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          alt="icon"
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          src={leftArr}
          decoding="async"
          loading="lazy"
          className={styles.portfolioArrowIcon}
          alt="icon"
        />
      </div>
    </div>
  )
}

const Authors = ({ authors }) => {
  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "button__bar",
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        arrows: false,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section>
      <div className="text-center mb-3 px-3">
        <h2 className={`mb-5 pb-5 main-heading-36`}>Meet Our Wordsmiths</h2>
      </div>
      <Container>
        <Slider {...settings}>
          {authors?.map(author => (
            <AvatarCard
              author={author}
              slug={author?.slug}
              key={author?.slug}
            />
          ))}
        </Slider>
      </Container>
    </section>
  )
}

export default Authors
